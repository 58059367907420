<template>
    <div class="main-container credits" v-if="pageData">
        <header-intro :pagedata="pageData"></header-intro>
        <div class="main-content">
            <div class="container">
                <div class="content">
                    <h2>{{ this.$store.state.labelData[this.lang].credits.main.toUpperCase() }}</h2>
                    <div class="logo_grid">

                        <figure>
                            <images :imagedata="pageData" :fieldname="'field_page_immagini'" :resultindex="2"
                                :breakstyles="{'0': 'sm_image', '576': 'md_image'}"></images>
                            <!--<figcaption>Camera di Commercio Venezia Giulia Trieste Gorizia - Gestione Fondo Gorizia</figcaption>-->
                        </figure>

                        <figure>
                            <images :imagedata="pageData" :fieldname="'field_page_immagini'" :resultindex="4"
                                :breakstyles="{'0': 'sm_image', '576': 'md_image'}"></images>
                            <!--<figcaption>Ente turismo FVG</figcaption>-->
                        </figure>
                    </div>
                </div>

                <div class="content">
                    <h2>{{ this.$store.state.labelData[this.lang].credits.in_collab.toUpperCase() }}</h2>

                    <div class="logo_grid">

                        <figure>
                            <images :imagedata="pageData" :fieldname="'field_page_immagini'" :resultindex="0"
                                :breakstyles="{'0': 'sm_image', '576': 'md_image'}"></images>
                            <!--<figcaption>Soprintendenza Archeologica Belle Arti e Paesaggio del Friuli Venezia GIulia</figcaption>-->
                        </figure>

                        <figure>
                            <images :imagedata="pageData" :fieldname="'field_page_immagini'" :resultindex="1"
                                :breakstyles="{'0': 'sm_image', '576': 'md_image'}"></images>
                            <!--<figcaption>Università degli Studi di Trieste Dipartimento di Studi Umanistici</figcaption>-->
                        </figure>

                    </div>
                    <br>

                    <div class="logo_grid">
                        <div class="names">
                            <h2>{{ this.$store.state.labelData[this.lang].credits.counsel.toUpperCase() }}</h2>
                            <p>Paola Maggi, Giulia Mian</p>
                        </div>
                        <div>
                            <h2>{{ this.$store.state.labelData[this.lang].credits.tech.toUpperCase() }}</h2>
                            <images class="interfase-logo" :imagedata="pageData" :fieldname="'field_page_immagini'" :resultindex="3"
                                :breakstyles="{'0': 'sm_image', '576': 'md_image'}"></images>
                        </div>
                    </div>

                </div>

                <div class="content" v-if="this.pageData.field_ref_ui_text && this.pageData.field_ref_ui_text.length">
                    <div v-html="this.pageData.field_ref_ui_text[0].attributes.field_text_with_summary.value">
                    </div>
                    <div class="small"
                        v-html="this.pageData.field_ref_ui_text[0].attributes.field_text_with_summary.summary"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import HeaderIntro from '../components/HeaderIntro'
    import Images from '../components/Images'
    import {
        fetchSinglePage,
        getTranslation
    } from '../libs/drupalClient'
    export default {
        name: 'credits',
        components: {
            HeaderIntro,
            Images
        },
        data() {
            return {
                pageData: {
                    attributes: {
                        title: "Credits"
                    }
                },
                currentPageID: "7c47db39-986e-4c59-bb1d-8b7f25ed3431"
            }
        },
        metaInfo() {
            return {
                title: this.pagemetatitle,
                meta: [{
                    property: 'og:title',
                    content: this.pagemetatitle
                }]
            }
        },
        computed: {
            pagemetatitle() {
                if(this.lang == 'it') return 'Credits'
                else if(this.lang=='de') return 'Credits'
                else return 'Credits'
            },
            lang() {
                return this.$store.state.currentLang
            },
            defaultLang() {
                return this.$store.state.defaultLang
            }
        },
        mounted() {
            fetchSinglePage(this.currentPageID, this.lang)
                .then(res => {
                    //console.log(res)
                    this.pageData = res
                })
        },
        watch: {
            lang() {
                getTranslation(this.$route.name, this.lang)
                    .then(res => {
                        this.$router.push({
                            path: res
                        })
                    })

                fetchSinglePage(this.currentPageID, this.lang)
                    .then(res => this.pageData = res)
            }
        }
    }
</script>

<style lang="scss" scoped>
@import "../styles/variables.scss";

.logo_grid > div picture.interfase-logo {
    max-width: 210px;
    ::v-deep img { width: 100%; }
}

.content h2 {
    margin-top: 4rem;
    font-size: 1.25rem;
    line-height: 1.625rem;
    color: #C3923F;
}

figcaption {
    margin-top: 10px;
    margin-bottom: 40px;
    text-align: left;
}

.credits .main-content { margin: 0rem 0 3rem 0; }

.small { font-size: .8rem; }

p span {
    margin-bottom: .5em;
    display: block;
}

.names { margin-bottom: 3rem; }

.logo_grid {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-direction: column;
    margin: 1rem 0rem;

    &>div,
    figure {
        width: 100%;

        picture {
            width: 100%;
            //border: 1px solid rgba(255, 0, 0, 0.075);
            display: flex;
            height: 100%;
            align-items: flex-end;

            ::v-deep img {
                width: 100%;
                height: 100%;
                max-height: 80px;
                object-fit: contain;
                margin-bottom: 10px;
            }
        }
    }
}

h2 {
    font-size: 1rem;
    margin: 2rem 0 1rem 0;
}

figure {
    margin: .25em 0;
    img { width: 100%; }
}

.btn {
    text-decoration: none;
    text-transform: uppercase;
}

@media (min-width: 480px) {
    .content h2 { margin-top: 2rem; }

    .logo_grid>div picture {
        max-width: 350px;
        margin: 0px auto 0px 0px;
        height: 100px;
        ::v-deep img { width: auto; }
    }

    .names { margin-bottom: 2rem; }

    .logo_grid figure {
        picture {
            max-width: 350px;
            margin: 0px auto 0px 0px;
            height: 100px;

            ::v-deep img {
                width: auto;
                max-width: 100%;
            }
        }
    }
}

@media (min-width: 768px) {
    .logo_grid {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &>div, figure { width: 44%; }
    }

    h2 { width: 80%; }

    .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .content h2 { margin-top: 1rem; }
}

@media (min-width: 992px) {
    h2 { width: 55%; }

    .container { max-width: 1140px; }

    ::v-deep img {
        max-width: 240px;
        max-height: 100px;
        width: auto;
        height: auto;
    }
}

@media (min-width: 1024px) {
    figcaption {
        margin-top: 10px;
        margin-bottom: 20px;
        br { display: none; }
    }

    h2 { width: auto; }

    ::v-deep picture {
        width: 450px;
        height: 100px;
        position: relative;
        overflow: hidden;
        display: flex;
        align-items: center;

        img {
            max-width: 100%;
            max-height: none;
            bottom: 0;
            height: 100px;
            width: auto;
            -o-object-fit: contain;
            object-fit: contain;
        }
    }
}
</style>